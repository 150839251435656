
  import { defineComponent, onMounted } from 'vue';
  // import TableWidget9 from '@/components/widgets/tables/Widget9.vue';
  // import TableWidget5 from '@/components/widgets/tables/Widget5.vue';
  // import ListWidget1 from '@/components/widgets/lists/Widget1.vue';
  // import ListWidget2 from '@/components/widgets/lists/Widget2.vue';
  // import ListWidget3 from '@/components/widgets/lists/Widget3.vue';
  // import ListWidget6 from '@/components/widgets/lists/Widget6.vue';

  import AdsWidget from '@/components/widgets/mixed/AdsWidget.vue';
  import RegisteredTradesWidget from '@/components/widgets/mixed/RegisteredTradesWidget.vue';
  import AuctionWidget from '@/components/widgets/mixed/AuctionWidget.vue';
  import { useAbility } from '@casl/vue';

  // import MixedWidget5 from '@/components/widgets/mixed/Widget5.vue';
  // import MixedWidget7 from '@/components/widgets/mixed/Widget7.vue';
  // import MixedWidget10 from '@/components/widgets/mixed/Widget10.vue';
  import UsersWidget from '@/components/widgets/mixed/UsersWidget.vue';
  import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import EstimatorWidget from '@/components/widgets/lists/EstimatorWidget.vue';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';

  export default defineComponent({
    name: 'dashboard',
    components: {
      // TableWidget9,
      // TableWidget5,
      // ListWidget1,
      // ListWidget2,
      // ListWidget6,
      RegisteredTradesWidget,
      // MixedWidget5,
      // MixedWidget7,
      // MixedWidget10,
      UsersWidget,
      AdsWidget,
      AuctionWidget,
      EstimatorWidget,
    },
    setup() {
      const { can } = useAbility();
      const store = useStore();

      onMounted(() => {
        setCurrentPageTitle('Dashboard');
      });
      reinitializeComponents();
      setTimeout(() => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');
      }, 1500);

      return {
        can,
      };
    },
  });
