
  import { defineComponent, ref, computed } from 'vue';
  import EstimatorFilterWidgets from '@/components/dropdown/EstimatorFilterWidgets.vue';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import { useI18n } from 'vue-i18n/index';
  import Swal from 'sweetalert2/dist/sweetalert2.js';

  export default defineComponent({
    name: 'kt-estimator-widget',
    components: {
      EstimatorFilterWidgets,
    },
    props: {
      widgetClasses: String,
    },
    async setup() {
      const { t, te } = useI18n();
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const loading = ref(true);
      const store = useStore();
      await store.dispatch(Actions.GET_ESTIMATOR_DASHBOARD);
      let data = store.getters.estimatorDashboard;
      const counts = {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
      };

      data.data.forEach((item) => {
        if (item.type in counts) {
          counts[item.type] = item._count.id || 0;
        }
      });

      //
      // console.log(counts);
      // const items = ref();

      const list = ref([
        {
          color: 'warning',
          icon: 'icons/duotune/abstract/abs027.svg',
          title: 'IVE',
          text: '',
          number: counts[1],
        },
        {
          color: 'success',
          icon: 'icons/duotune/art/art005.svg',
          title: 'IRE',
          text: 'Old Ire',
          number: counts[2],
        },
        {
          color: 'danger',
          icon: 'icons/duotune/communication/com012.svg',
          title: 'CCE',
          text: '',
          number: counts[3],
        },
        {
          color: 'info',
          icon: 'icons/duotune/communication/com012.svg',
          title: 'IRE PRIVATE',
          text: '',
          number: counts[4],
        },
        {
          color: 'dark',
          icon: 'icons/duotune/communication/com012.svg',
          title: 'IRE INVESTMENT',
          text: '',
          number: counts[5],
        },
      ]);

      const onReset = async (value) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_ESTIMATOR_DASHBOARD_FILTER, value);
        await store.dispatch(Actions.GET_ESTIMATOR_DASHBOARD);
        loading.value = false;
      };

      const onChanges = async (values) => {
        loading.value = true;
        await store.dispatch(
          Actions.UPDATE_ESTIMATOR_DASHBOARD_FILTER,
          values.value
        );
        await store.dispatch(Actions.GET_ESTIMATOR_DASHBOARD, values);
        loading.value = false;
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];
        list.value = [];

        data = store.getters.estimatorDashboard;
        const counts = {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
        };

        data.data.forEach((item) => {
          if (item.type in counts) {
            counts[item.type] = item._count.id || 0;
          }
        });

        //
        // console.log(counts);
        // const items = ref();

        list.value = [
          {
            color: 'warning',
            icon: 'icons/duotune/abstract/abs027.svg',
            title: 'IVE',
            text: '',
            number: counts[1],
          },
          {
            color: 'success',
            icon: 'icons/duotune/art/art005.svg',
            title: 'IRE',
            text: 'Old Ire',
            number: counts[2],
          },
          {
            color: 'danger',
            icon: 'icons/duotune/communication/com012.svg',
            title: 'CCE',
            text: '',
            number: counts[3],
          },
          {
            color: 'info',
            icon: 'icons/duotune/communication/com012.svg',
            title: 'IRE PRIVATE',
            text: '',
            number: counts[4],
          },
          {
            color: 'dark',
            icon: 'icons/duotune/communication/com012.svg',
            title: 'IRE INVESTMENT',
            text: '',
            number: counts[5],
          },
        ];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        }
      };

      setTimeout(() => {
        loading.value = false;
        reinitializeComponents();
      }, 900);

      return {
        data: computed(() => store.getters.estimatorDashboard),
        onChanges,
        onReset,
        loading,
        list,
      };
    },
  });
