
  import { defineComponent, ref, computed } from 'vue';
  import { getCSSVariableValue } from '@/assets/ts/_utils';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import UsersFilterWidgets from '@/components/dropdown/UsersFilterWidgets.vue';

  export default defineComponent({
    name: 'user-widget',
    components: {
      UsersFilterWidgets,
    },
    props: {
      widgetClasses: String,
      chartColor: String,
      chartHeight: String,
    },
    async setup(props) {
      const loading = ref(true);
      const store = useStore();
      await store.dispatch(Actions.GET_USERS_DASHBOARD);
      const data = store.getters.usersDashboard;
      const individualUserObj = data.data.countPerMonth.filter(
        (item) => item.member_type == 1
      );
      const businessUserObj = data.data.countPerMonth.filter(
        (item) => item.member_type == 2
      );

      const MONTHS = {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'Jun',
        7: 'Jul',
        8: 'Aug',
        9: 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec',
      };

      const chartMonth = individualUserObj.map((item) => MONTHS[item.month]);
      const chartMonth1 = businessUserObj.map((item) => MONTHS[item.month]);

      const chartCountIndividual = individualUserObj.map((item) => item.count);
      const chartCountBusiness = businessUserObj.map((item) => item.count);

      const color = ref(props.chartColor);

      const labelColor = getCSSVariableValue('--bs-gray-500');
      const borderColor = getCSSVariableValue('--bs-gray-200');
      const secondaryColor = getCSSVariableValue('--bs-gray-300');
      const baseColor = getCSSVariableValue('--bs-' + color.value);

      setTimeout(() => {
        loading.value = false;
        reinitializeComponents();
      }, 900);

      const chartOptions = {
        chart: {
          fontFamily: 'inherit',
          type: 'bar',
          height: props.chartHeight,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: ['50%'],
            endingShape: 'rounded',
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: chartMonth,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: labelColor,
              fontSize: '12px',
            },
          },
        },
        yaxis: {
          y: 0,
          offsetX: 0,
          offsetY: 0,
          labels: {
            style: {
              colors: labelColor,
              fontSize: '12px',
            },
          },
        },
        fill: {
          type: 'solid',
        },
        states: {
          normal: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          hover: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'none',
              value: 0,
            },
          },
        },
        tooltip: {
          style: {
            fontSize: '12px',
          },
          y: {
            formatter: function (val) {
              return '# ' + val + ' Users';
            },
          },
        },
        colors: [baseColor, secondaryColor],
        grid: {
          padding: {
            top: 10,
          },
          borderColor: borderColor,
          strokeDashArray: 1,
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
      };

      const chartOptions1 = {
        chart: {
          fontFamily: 'inherit',
          type: 'bar',
          height: props.chartHeight,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: ['50%'],
            endingShape: 'rounded',
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: chartMonth1,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: labelColor,
              fontSize: '12px',
            },
          },
        },
        yaxis: {
          y: 0,
          offsetX: 0,
          offsetY: 0,
          labels: {
            style: {
              colors: labelColor,
              fontSize: '12px',
            },
          },
        },
        fill: {
          type: 'solid',
        },
        states: {
          normal: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          hover: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'none',
              value: 0,
            },
          },
        },
        tooltip: {
          style: {
            fontSize: '12px',
          },
          y: {
            formatter: function (val) {
              return '# ' + val + ' Users';
            },
          },
        },
        colors: [baseColor, secondaryColor],
        grid: {
          padding: {
            top: 10,
          },
          borderColor: borderColor,
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
      };

      const series = [
        {
          name: 'Individual Users',
          data: chartCountIndividual,
        },
        // {
        //   name: 'Business Users',
        //   data: chartCountBusiness,
        // },
      ];
      const series1 = [
        {
          name: 'Business Users',
          data: chartCountBusiness,
        },
      ];

      const onTypeChange = async (values) => {
        loading.value = true;
        await store.dispatch(
          Actions.UPDATE_USERS_DASHBOARD_FILTER,
          values.value
        );
        await store.dispatch(Actions.GET_USERS_DASHBOARD, values);
        await store.dispatch(
          Actions.UPDATE_USERS_DASHBOARD_FILTER,
          values.value
        );

        setTimeout(() => {
          loading.value = false;
          reinitializeComponents();
        }, 900);
      };
      const onReset = async (value) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_USERS_DASHBOARD_FILTER, value);
        await store.dispatch(Actions.GET_USERS_DASHBOARD);
        loading.value = false;
      };

      return {
        onTypeChange,
        onReset,
        data: computed(() => store.getters.usersDashboard),
        loading,
        chartOptions,
        chartOptions1,
        series,
        series1,
      };
    },
  });
