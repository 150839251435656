
  import { defineComponent, ref, computed } from 'vue';
  import RegisteredTradesFilterWidgets from '@/components/dropdown/RegisteredTradesFilterWidgets.vue';
  import { getCSSVariableValue } from '@/assets/ts/_utils';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';

  export default defineComponent({
    name: 'registered-trades-widget',
    components: {
      RegisteredTradesFilterWidgets,
    },
    props: {
      widgetClasses: String,
      widgetColor: String,
      strokeColor: String,
      chartHeight: String,
    },
    async setup(props) {
      const loading = ref(true);
      const store = useStore();
      await store.dispatch(Actions.GET_REGISTERED_TRADE_DASHBOARD);

      const data = store.getters.registeredTradesDashboard;

      // const labelColor = getCSSVariableValue('--bs-gray-500');
      // const borderColor = getCSSVariableValue('--bs-gray-200');
      const color = ref(props.widgetColor);
      // const color = getCSSVariableValue('--bs-' + props.widgetColor);
      // const labelColor = getCSSVariableValue("--bs-" + "gray-800");
      // const strokeColor = getCSSVariableValue("--bs-" + "gray-300");
      // const baseColor = getCSSVariableValue("--bs-" + color.value);
      // const lightColor = getCSSVariableValue("--bs-light-" + color.value);
      const lightColor = getCSSVariableValue('--bs-light-' + color.value);
      const labelColor = getCSSVariableValue('--bs-' + 'gray-800');
      const strokeColor = getCSSVariableValue('--bs-' + 'gray-300');
      const baseColor = getCSSVariableValue('--bs-' + color.value);
      // const strokeColor = ref(props.strokeColor);

      const MONTHS = {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'Jun',
        7: 'Jul',
        8: 'Aug',
        9: 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec',
      };

      const chartMonth = data.data.countPerMonth.map(
        (item) => MONTHS[item.month]
      );
      const chartCount = data.data.countPerMonth.map((item) => item.count);

      setTimeout(() => {
        loading.value = false;
        reinitializeComponents();
      }, 900);

      const chartOptions = {
        chart: {
          fontFamily: 'inherit',
          type: 'area',
          height: props.chartHeight,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {},
        legend: {
          show: true,
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: 'solid',
          opacity: 1,
        },
        stroke: {
          curve: 'smooth',
          show: true,
          width: 3,
          colors: [baseColor],
        },
        xaxis: {
          categories: chartMonth,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            style: {
              colors: labelColor,
              fontSize: '12px',
            },
          },
          crosshairs: {
            show: false,
            position: 'front',
            stroke: {
              color: strokeColor,
              width: 1,
              dashArray: 3,
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          min: 0,
          max: 600,
          labels: {
            show: false,
            style: {
              colors: labelColor,
              fontSize: '12px',
            },
          },
        },
        states: {
          normal: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          hover: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'none',
              value: 0,
            },
          },
        },
        tooltip: {
          style: {
            fontSize: '12px',
          },
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        colors: [lightColor],
        markers: {
          colors: [lightColor],
          strokeColor: [baseColor],
          strokeWidth: 3,
        },
      };
      const series = [
        {
          name: 'Number Of Registered Trades',
          data: chartCount,
        },
      ];

      const onTypeChange = async (values) => {
        loading.value = true;
        await store.dispatch(
          Actions.UPDATE_REGISTERED_TRADE_DASHBOARD_FILTER,
          values.value
        );
        store.dispatch(Actions.GET_REGISTERED_TRADE_DASHBOARD, values);

        setTimeout(() => {
          loading.value = false;
        }, 900);
      };
      const onReset = async (value) => {
        loading.value = true;
        await store.dispatch(
          Actions.UPDATE_REGISTERED_TRADE_DASHBOARD_FILTER,
          value
        );
        await store.dispatch(Actions.GET_REGISTERED_TRADE_DASHBOARD);
        loading.value = false;
      };

      // console.log(
      // console.log(chartCount)
      return {
        data: computed(() => store.getters.registeredTradesDashboard),
        onTypeChange,
        onReset,
        loading,
        series,
        chartOptions,
      };
    },
  });
