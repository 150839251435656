
  import { defineComponent, ref, computed } from 'vue';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import moment from 'moment';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';

  export default defineComponent({
    name: 'ads-filter-widgets',
    emit: ['filter-selected-ads', 'reset'],
    components: {},
    async setup(props, { emit }) {
      const { t, te } = useI18n();
      const store = useStore();
      const adsFilterValue = await store.getters.adsFilterValue;
      const selectedItems = ref<any>({
        dateFrom: adsFilterValue.dateFrom ? adsFilterValue.dateFrom : '',
        dateTo: adsFilterValue.dateTo ? adsFilterValue.dateTo : '',
        areaId: '',
      });
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const onSubmit = () => {
        if (selectedItems.value.dateFrom) {
          selectedItems.value.dateFrom = moment(
            selectedItems.value.dateFrom
          ).format('YYYY-MM-DD');
        }
        if (selectedItems.value.dateTo) {
          selectedItems.value.dateTo = moment(
            selectedItems.value.dateTo
          ).format('YYYY-MM-DD');
        }
        emit('filter-selected', selectedItems);
      };

      const onReset = () => {
        selectedItems.value = {
          dateFrom: '',
          dateTo: '',
          areaId: '',
        };
        emit('reset', selectedItems.value);
      };

      reinitializeComponents();

      return {
        areas: computed(() => store.getters.allListAreas),
        propertyTypes: computed(() => store.getters.propertyTypesAllList),
        onSubmit,
        onReset,
        selectedItems,
        translate,
      };
    },
  });
