import { illustrationsSet } from '@/core/helpers/config';

export const converter = (val: number): string => {
  return val
    .toString()
    .replace(/\D/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const getIllustrationsPath = (illustrationName: string): string => {
  return `media/illustrations/${illustrationsSet.value}/${illustrationName}`;
};
