
  import { defineComponent, ref, computed } from 'vue';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import moment from 'moment';
  import { Actions } from '@/store/enums/StoreEnums';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import { RegisteredTradesFilterInfo } from '@/store/modules/RegisteredTrades/RegisteredTradesModule';

  export default defineComponent({
    name: 'registered-trades-filter-widgets',
    emit: ['filter-selected-registered-trades', 'reset'],
    components: {},
    async setup(props, { emit }) {
      const { t, te } = useI18n();
      const store = useStore();
      const registeredFilter = await store.getters.registeredFilterValue;
      const selectedItems = ref<RegisteredTradesFilterInfo>({
        dateFrom: registeredFilter.dateFrom ? registeredFilter.dateFrom : '',
        dateTo: registeredFilter.dateTo ? registeredFilter.dateTo : '',
        areaId: registeredFilter.areaId ? registeredFilter.areaId : '',
        priceFrom: undefined,
        priceTo: undefined,
        size: undefined,
        noteAr: '',
        propertyStatusId: registeredFilter.propertyStatusId
          ? registeredFilter.propertyStatusId
          : '',
        propertyTypeId: registeredFilter.propertyTypeId
          ? registeredFilter.propertyTypeId
          : '',
      });
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const onSubmit = () => {
        if (selectedItems.value.dateFrom) {
          selectedItems.value.dateFrom = moment(
            selectedItems.value.dateFrom
          ).format('YYYY-MM-DD');
        }
        if (selectedItems.value.dateTo) {
          selectedItems.value.dateTo = moment(
            selectedItems.value.dateTo
          ).format('YYYY-MM-DD');
        }
        emit('filter-selected', selectedItems);
      };

      const onReset = () => {
        selectedItems.value = {
          dateFrom: '',
          dateTo: '',
          areaId: '',
          priceFrom: undefined,
          priceTo: undefined,
          size: undefined,
          noteAr: '',
          propertyStatusId: '',
          propertyTypeId: '',
        };
        emit('reset', selectedItems.value);
      };
      await store.dispatch(Actions.GET_ALL_AREAS_LIST, {
        type: 'registeredTrades',
      });
      await store.dispatch(Actions.GET_ALL_PROPERTY_TYPE, {
        type: 'registeredTrades',
      });
      reinitializeComponents();

      return {
        areas: computed(() => store.getters.allListAreas),
        propertyTypes: computed(() => store.getters.propertyTypesAllList),
        onSubmit,
        onReset,
        selectedItems,
        translate,
      };
    },
  });
