
  import { defineComponent, ref, computed } from 'vue';
  import AdsFilterWidgets from '@/components/dropdown/AdsFilterWidgets.vue';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';

  import { converter } from '@/core/helpers/assets';
  import { getCSSVariableValue } from '@/assets/ts/_utils';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';

  export default defineComponent({
    name: 'AdsWidget',
    components: {
      AdsFilterWidgets,
    },
    props: {
      widgetClasses: String,
      widgetColor: String,
      chartHeight: Number,
    },
    async setup(props) {
      const loading = ref(true);

      const color = ref(props.widgetColor);

      const labelColor = getCSSVariableValue('--bs-' + 'gray-800');
      const strokeColor = getCSSVariableValue('--bs-' + 'gray-300');
      const baseColor = getCSSVariableValue('--bs-' + color.value);
      const lightColor = getCSSVariableValue('--bs-light-' + color.value);

      const store = useStore();
      // const router = useRouter();
      // const route = useRoute();

      await store.dispatch(Actions.GET_ADS_DASHBOARD);
      let data = store.getters.adsDashboard;

      // const property = ref<any>(data.data);
      // console.log(data);

      data.data.totalAds = converter(data.data.totalAds);
      data.data.totalPaidAds = converter(data.data.totalPaidAds);
      data.data.totalFreeAds = converter(data.data.totalFreeAds);

      const MONTHS = {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'Jun',
        7: 'Jul',
        8: 'Aug',
        9: 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec',
      };

      const chartMonth = data.data.countPerMonth.map(
        (item) => MONTHS[item.month]
      );
      const chartCount = data.data.countPerMonth.map((item) => item.count);
      loading.value = false;
      setTimeout(() => {
        loading.value = false;

        reinitializeComponents();
      }, 900);
      const items = ref();

      items.value = [
        {
          icon: 'media/icons/duotune/icon001.svg',
          title: 'Free Ads',
          description: 'Number Of #',
          stats: data.data.totalFreeAds,
          arrow: 'up',
        },
        {
          icon: 'media/icons/duotune/finance/fin010.svg',
          title: 'Paid Ads',
          description: 'Number Of #',
          stats: data.data.totalPaidAds,
          arrow: 'down',
        },
        {
          icon: 'media/icons/duotune/maps/map008.svg',
          title: 'Ad Type',
          description: 'Most Common Ad Type',
          stats: data.data.adsType,
          arrow: 'up',
        },
        {
          icon: 'media/icons/duotune/maps/map008.svg',
          title: 'area',
          description: 'Most Common Area',
          stats: data.data.area,
          arrow: 'up',
        },
        {
          icon: 'media/icons/duotune/general/gen005.svg',
          title: 'Property Category',
          description: 'Most Common Property Category',
          stats: data.data.propertyCategory,
          arrow: 'down',
        },
        {
          icon: 'media/icons/duotune/general/gen005.svg',
          title: 'Property Type',
          description: 'Most Common Property Type',
          stats: data.data.propertyType,
          arrow: 'down',
        },
      ];

      const chartOptions = {
        chart: {
          fontFamily: 'inherit',
          type: 'area',
          height: props.chartHeight,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {},
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: 'solid',
          opacity: 1,
        },
        stroke: {
          curve: 'smooth',
          show: true,
          width: 3,
          colors: [baseColor],
        },
        xaxis: {
          categories: chartMonth,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            style: {
              colors: labelColor,
              fontSize: '12px',
            },
          },
          crosshairs: {
            show: false,
            position: 'front',
            stroke: {
              color: strokeColor,
              width: 1,
              dashArray: 3,
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          min: 2000,
          max: 16000,
          labels: {
            show: false,
            style: {
              colors: labelColor,
              fontSize: '12px',
            },
          },
        },
        states: {
          normal: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          hover: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'none',
              value: 0,
            },
          },
        },
        tooltip: {
          style: {
            fontSize: '12px',
          },
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        colors: [lightColor],
        markers: {
          colors: [lightColor],
          strokeColor: [baseColor],
          strokeWidth: 3,
        },
      };

      // const objMonth = data.data.
      const series = [
        {
          name: 'Number Of Auctions',
          data: chartCount,
        },
      ];

      const onTypeChange = async (values) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_ADS_DASHBOARD_FILTER, values.value);
        await store.dispatch(Actions.GET_ADS_DASHBOARD, values);
        data = store.getters.adsDashboard;
        data.data.totalAds = converter(data.data.totalAds);
        data.data.totalPaidAds = converter(data.data.totalPaidAds);
        data.data.totalFreeAds = converter(data.data.totalFreeAds);

        items.value = [];
        items.value = [
          {
            icon: 'media/icons/duotune/icon001.svg',
            title: 'Free Ads',
            description: 'Number Of #',
            stats: data.data.totalFreeAds,
            arrow: 'up',
          },
          {
            icon: 'media/icons/duotune/finance/fin010.svg',
            title: 'Paid Ads',
            description: 'Number Of #',
            stats: data.data.totalPaidAds,
            arrow: 'down',
          },
          {
            icon: 'media/icons/duotune/maps/map008.svg',
            title: 'Ad Type',
            description: 'Most Common Ad Type',
            stats: data.data.adsType,
            arrow: 'up',
          },
          {
            icon: 'media/icons/duotune/maps/map008.svg',
            title: 'area',
            description: 'Most Common Area',
            stats: data.data.area,
            arrow: 'up',
          },
          {
            icon: 'media/icons/duotune/general/gen005.svg',
            title: 'Property Category',
            description: 'Most Common Property Category',
            stats: data.data.propertyCategory,
            arrow: 'down',
          },
          {
            icon: 'media/icons/duotune/general/gen005.svg',
            title: 'Property Type',
            description: 'Most Common Property Type',
            stats: data.data.propertyType,
            arrow: 'down',
          },
        ];
        reinitializeComponents();

        setTimeout(() => {
          loading.value = false;
          reinitializeComponents();
        }, 900);
      };
      const onReset = async (value) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_ADS_DASHBOARD_FILTER, value);
        await store.dispatch(Actions.GET_ADS_DASHBOARD);
        loading.value = false;
      };

      return {
        items,
        onTypeChange,
        onReset,
        loading,
        data: computed(() => store.getters.adsDashboard),
        chartOptions,
        series,
      };
    },
  });
