import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-5 g-xl-8" }
const _hoisted_2 = { class: "col-xxl-4" }
const _hoisted_3 = { class: "col-xxl-4" }
const _hoisted_4 = { class: "col-xxl-4" }
const _hoisted_5 = { class: "row gy-5 gx-xl-8" }
const _hoisted_6 = {
  class: "col-xxl-4",
  style: {"height":"100%"}
}
const _hoisted_7 = { class: "col-xxl-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdsWidget = _resolveComponent("AdsWidget")!
  const _component_RegisteredTradesWidget = _resolveComponent("RegisteredTradesWidget")!
  const _component_AuctionWidget = _resolveComponent("AuctionWidget")!
  const _component_UsersWidget = _resolveComponent("UsersWidget")!
  const _component_EstimatorWidget = _resolveComponent("EstimatorWidget")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_AdsWidget, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "widget-color": "info",
          "chart-height": "200",
          "stroke-color": "#cb1e46"
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_RegisteredTradesWidget, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "widget-color": "danger",
          "chart-height": "200",
          "stroke-color": "#cb1e46"
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_AuctionWidget, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "widget-color": "danger",
          "chart-color": "primary",
          "chart-height": "200",
          "stroke-color": "#cb1e46"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_UsersWidget, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "168"
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_EstimatorWidget, { "widget-classes": "card-xl-stretch mb-xl-8" })
      ])
    ])
  ], 64))
}